<template>
  <div style="padding: 10px">
    <el-card
      v-for="(item, index) in message.data.content"
      :key="index"
      :body-style="{ padding: '0px' }"
      style="margin-bottom: 10px"
      class="compact-container"
    >
      <div class="compact-content">
        <div
          v-if="itemImage(item)"
          :style="imageStyle(item)"
          class="image"
          style="background-position: center; background-size: cover"
          @click="$emit('setLightBoxImage', item.image)"
        />
        <div style="padding: 8px" class="content" :style="isUiBetaEnabled && backgroundStyle">
          <span
            class="kr-title"
            v-text="item.text"
            style="word-break: break-word"
            :style="isUiBetaEnabled && titleStyle"
          />
          <div style="margin: 4px 0">
            <span
              class="subtext"
              style="word-break: break-word; white-space: pre-line"
              v-html="item.subtext"
            ></span>
          </div>
          <div
            v-for="(button, buttonIndex) in item.buttons"
            :key="buttonIndex"
            style="text-align: center; display: block"
            class="compact-button"
          >
            <el-button
              class="kr-button"
              :class="{ 'blue-button': buttonOutline === 'primary' }"
              v-text="button.text"
              size="small"
              target="_blank"
              style="width: 100%; margin-bottom: 8px"
              :type="buttonOutline"
              :plain="true"
              :disabled="isDisabledButton(button)"
              @click.stop.prevent="$emit('sendPostback', button)"
            />
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
import isDisabledButton from "@/helpers/isDisabledButton";
import { mapGetters } from "vuex";

export default {
  props: ["message", "buttonOutline", "shouldDisableIfInLivechat", "backgroundStyle", "titleStyle"],
  methods: {
    imageStyle(item) {
      return {
        backgroundSize: _.get(item, "options.padded") ? "contain" : "cover",
        backgroundImage: `url("${this.itemImage(item)}")`,
      };
    },
    isDisabledButton(button) {
      return isDisabledButton(this, button);
    },
    itemImage(item) {
      const image = item.image || _.get(item, "images[0]");
      return image;
    },
  },
  computed: {
    ...mapGetters(["isUiBetaEnabled"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-beta {
  .compact-container {
    border-radius: 16px;
    overflow: hidden;

    .compact-content {
      display: flex;
    }
    .compact-button {
      text-align: left;
      width: 100%;
      margin-top: 20px;

      .kr-button {
        padding: 8px;
        white-space: normal !important;
        line-height: 18px;
      }
    }
  }

  .image {
    max-width: 200px;
    width: 40%;
  }
  .content {
    flex: 1;
    background: #dce2f5;

    .kr-title {
      color: #4e6cce;
      font-size: 14px;
      font-weight: 600;
    }

    .subtext {
      font-size: 14px;
      color: #333;
    }
  }
  .kr-button {
    padding: 5px;
    border-radius: 8px;

    &.blue-button {
      border: 1px solid #4e6cce;
      color: #4e6cce;
      background: #fff;

      &:hover {
        color: #fff;
        background: #4e6cce;
      }
    }
  }
}
</style>
