var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper',{ref:"kr-carousel",staticStyle:{"margin":"10px 0","padding-bottom":"10px"},style:({
    overflow: _vm.isMobileSafari ? 'auto' : 'hidden',
  }),attrs:{"options":{ ..._vm.swiperOptions, allowTouchMove: _vm.allowTouchMove }},on:{"slideChange":_vm.handleSlideChange}},[_vm._l((_vm.message.data.content),function(item,index){return _c('swiper-slide',{key:index,staticStyle:{"max-width":"65%","height":"auto"}},[_c('el-card',{staticClass:"message-card-item",staticStyle:{"height":"fit-content"},style:(_vm.isUiBetaEnabled && _vm.backgroundStyle),attrs:{"body-style":{
        padding: '0',
        height: 'fit-content',
        display: _vm.itemImage(item) && item.text ? 'flex' : 'block',
        flexDirection: 'column',
      }}},[(_vm.itemImage(item) && item.text)?_c('div',{staticStyle:{"background":"#fff"}},[(_vm.itemImage(item))?_c('div',{class:!_vm.settings.disableLightBoxImage ? 'image has-cursor-pointer' : 'image',staticStyle:{"background-position":"center","background-repeat":"no-repeat","height":"160px"},style:(_vm.imageStyle(item, _vm.itemImage(item))),on:{"click":function($event){return _vm.setLightBoxImage(item)}}}):_vm._e()]):(_vm.itemImage(item))?_c('div',[_c('a',{class:!_vm.settings.disableLightBoxImage ? 'has-cursor-pointer' : '',on:{"click":function($event){return _vm.setLightBoxImage(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.itemImage(item)}})])]):_vm._e(),_c('div',{staticClass:"message-card-content",staticStyle:{"display":"flex","flex-direction":"column","height":"100%"}},[(item.text || item.subtext)?_c('div',{staticStyle:{"padding":"8px"}},[_c('div',{staticStyle:{"line-height":"18px"}},[_c('span',{staticClass:"message-card-title",staticStyle:{"font-weight":"600","font-size":"16px"},style:({
                fontFamily: _vm.settings.font,
                color: _vm.isUiBetaEnabled && _vm.titleStyle.color,
              }),domProps:{"textContent":_vm._s(item.text)}})]),_c('div',{staticStyle:{"margin":"4px 0","line-height":"16px"}},[_c('small',{staticClass:"subtext",staticStyle:{"font-size":"14px, color: #333"},style:({
                fontFamily: _vm.settings.font,
              })},[_vm._v(_vm._s(item.subtext))])])]):_vm._e(),_c('el-row',{staticStyle:{"flex-direction":"column","padding":"0 10px"},attrs:{"type":"text"}},_vm._l((item.buttons),function(button,buttonIndex){return _c('el-button',{key:buttonIndex,staticClass:"message-card-button",class:{ 'blue-button': _vm.buttonOutline === 'primary' },staticStyle:{"width":"100%","margin":"0 0 10px 0","white-space":"normal"},style:({
              fontFamily: _vm.settings.font,
            }),attrs:{"size":_vm.isUiBetaEnabled ? 'medium' : 'small',"target":"_blank","plain":true,"type":_vm.buttonOutline,"disabled":_vm.isDisabledButton(button)},domProps:{"textContent":_vm._s(button.text)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('sendPostback', button)}}})}),1)],1),(_vm.totalSlides > 1 && _vm.isUiBetaEnabled)?_c('div',{staticClass:"navigation",class:{ disabled: _vm.current !== index }},[(_vm.current > 0)?_c('button',{staticClass:"navigation-prev",style:({ ..._vm.iconStyle, fontFamily: _vm.settings.font }),on:{"click":_vm.prev}},[_c('i',{class:_vm.navigationIconClasses.prev})]):_vm._e(),(_vm.current < _vm.totalSlides - 1)?_c('button',{staticClass:"navigation-next",style:({ ..._vm.iconStyle, fontFamily: _vm.settings.font }),on:{"click":_vm.next}},[_c('i',{class:_vm.navigationIconClasses.next})]):_vm._e()]):_vm._e()])],1)}),(_vm.message.data.content.length > 1 && !_vm.isUiBetaEnabled)?_c('button',{staticClass:"el-carousel__arrow el-carousel__arrow--left",staticStyle:{"background":"rgba(31, 45, 61, 0.5)","min-width":"auto"},style:({
      fontFamily: _vm.settings.font,
    }),attrs:{"slot":"button-prev"},on:{"click":_vm.prev},slot:"button-prev"},[_c('i',{staticClass:"el-icon-arrow-left"})]):_vm._e(),(_vm.message.data.content.length > 1 && !_vm.isUiBetaEnabled)?_c('button',{staticClass:"el-carousel__arrow el-carousel__arrow--right",staticStyle:{"background":"rgba(31, 45, 61, 0.5)","min-width":"auto"},style:({
      fontFamily: _vm.settings.font,
    }),attrs:{"slot":"button-next"},on:{"click":_vm.next},slot:"button-next"},[_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e(),(_vm.totalSlides > 1 && _vm.isUiBetaEnabled)?_c('div',{staticClass:"pagination",attrs:{"slot":"pagination"},slot:"pagination"},_vm._l((_vm.message.data.content),function(item,index){return _c('span',{key:index,staticClass:"dot",class:{ active: _vm.current === index },on:{"click":function($event){return _vm.handleNavigateSlide(index)}}})}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }