<template>
  <div style="display: flex; flex-direction: column">
    <el-button
      plain
      v-html="button.text"
      v-for="(button, buttonIndex) in message.data.content[0].buttons"
      size="small"
      class="message-bubble-button"
      :class="{ 'blue-button': buttonOutline === 'primary' }"
      style="width: 100%; margin: 0 0 10px 0; white-space: normal"
      :style="{
        fontFamily: settings.font,
      }"
      :type="buttonOutline"
      :key="`button-${buttonIndex}`"
      :disabled="isDisabledButton(button)"
      @click="onClick(button)"
    ></el-button>
  </div>
</template>

<script>
import _ from "lodash-es";
import isDisabledButton from "@/helpers/isDisabledButton";

export default {
  props: ["message", "buttonOutline", "shouldDisableIfInLivechat"],
  computed: {
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
  },
  methods: {
    isDisabledButton(button) {
      return isDisabledButton(this, button);
    },
    onClick(button) {
      const isUpload =
        button.text.toLowerCase().indexOf("upload") > -1 &&
        button.text.toLowerCase() !== "upload documents";
      if (isUpload) {
        this.$root.$emit("upload", button);
      } else {
        this.$emit("sendPostback", button);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-beta {
  .message-bubble-button {
    width: auto !important;
    word-wrap: break-word;
    font-size: 12px !important;
    border-radius: 8px;
    line-height: 16px;

    &.blue-button {
      border: 1px solid #4e6cce;
      color: #4e6cce;
      background: #fff;

      &:hover {
        color: #fff;
        background: #4e6cce;
      }
    }
  }
}
</style>
